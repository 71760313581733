import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { AxiosResponse } from 'axios'
import { companyIdAtom } from '@/main/store'
import { ICompanyAPIResponse, IUseCompany, IUseCompanyData } from './interfaces'
import { useMemo } from 'react'
import { EAdministrativeFeeCalculationType, EAdministrativeFeeValueType, ECalculationType, EDeskFeeType, ETipoTaxaEntrega } from '@/main/enums'

const organizeData = (data: ICompanyAPIResponse): IUseCompanyData => {
  if (!data) return null
  const deliveryFeeType = data.deliveryFees.some(fee => {
    const calcType = fee.calculationType as unknown as ECalculationType
    return fee.itemType === EDeskFeeType.delivery &&
      calcType === ECalculationType.ByOrder &&
      fee.active
  }) ? ETipoTaxaEntrega.Pedido : ETipoTaxaEntrega.Cartao
  console.log(deliveryFeeType)
  const deliveryFeeByOrder = data.deliveryFees
    .find(fee => fee.itemType === EDeskFeeType.delivery && fee.valueType === EAdministrativeFeeValueType.Fixed)
  return {
    activityBranch: data.activyBranch,
    activityBranchDescription: data.activyBranchDescription,
    addresses: [data.addressMain, ...data.addressDelivery],
    code: data.code,
    id: data.id,
    messageMigration: data.messageMigration,
    migrationId: data.migrationId,
    status: data.status,
    tradeName: data.tradeName,
    successFee: String(data.successFee),
    issInfo: !data.issInfo
      ? null
      : {
        id: data.issInfo.cityId,
        city: data.issInfo.customName,
        isActive: data.issInfo.active,
        value: String(data.issInfo.iss),
        status: data.issInfo.issStatus,
        issIncidences: data.issInfo.fees,
      },
    newBalanceProjection: data.newBalanceProjection,
    administrativeFees: data.administrateFees.map((fee) => ({
      benefit: fee.benefitType,
      calculationType: fee.calculationType,
      feeValue: String(fee.value),
      finalRange: String(fee.endRange),
      initialRange: String(fee.startRange),
      valueType: fee.valueType,
      id: fee.id,
    })),

    deliveryFees: data.deliveryFees.filter(fee => fee.calculationType !== EAdministrativeFeeCalculationType.Order).map((fee) => ({
      benefit: fee.benefitType,
      calculationType: fee.calculationType,
      feeValue: String(fee.value),
      finalRange: String(fee.endRange),
      initialRange: String(fee.startRange),
      valueType: fee.valueType,
      id: fee.id,
    })),
    orderFeeValue: String(deliveryFeeByOrder?.value),
    deliveryFeeByOrderId: deliveryFeeByOrder?.id,
    deliveryFeeType,
  }
}

export interface IUseCompanyReturn {
  company: IUseCompanyData
  isFetching: boolean
  isLoading: boolean
}

export const useCompany = (params: IUseCompany = {}): IUseCompanyReturn => {
  const { config = {} } = params
  const companyId = useAtomValue(companyIdAtom)
  const { data, ...rest } = useQuery<AxiosResponse<ICompanyAPIResponse>>({
    queryKey: ['company', companyId],
    queryFn: ({ queryKey }) => api.get(`resale-bff/companies/${queryKey[1]}`),
    ...config,
  })

  const company = useMemo(() => organizeData(data?.data), [data])

  return {
    company: company,
    isFetching: rest.isFetching,
    isLoading: rest.isLoading,
  }
}
