import { Grid, MaskedTextField, Skeleton } from "@stationkim/front-ui"
import { Controller, UseFormReturn } from "react-hook-form"

interface SlipFeeProps {
  form: UseFormReturn<any>
  isLoading?: boolean
}

export const SlipFee = ({ form, isLoading }: SlipFeeProps) => {
  return (
    <Grid container >
      <Grid item xs={12} sm={6} md={3}>
        {
          isLoading ?
            <Skeleton variant='rounded' height='40px' /> :
            <Controller
              control={form.control}
              name="slipFee"
              rules={{
                validate: {
                  isLowerOrEqualToFifty: (value) => Number(value) <= 50 ? true : 'A taxa máxima é de R$ 50,00'
                }
              }
              }
              render={({ field: { onChange, ref, value }, fieldState: { error } }) => {
                return (
                  <MaskedTextField
                    mask={'monetary'}
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    unmask
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )
              }}
            />
        }
      </Grid>
    </Grid>
  )
}