import api from '@/infra/api'
import { IStandardResponse } from '@/main/interfaces'
import { downloadFile } from '@/main/utils/functions'
import { useMutation } from '@tanstack/react-query'

interface IApiResponse {
  nomeArquivo: string
  arquivo: string
}

function base64ToUtf8(base64String) {
  const binaryString = atob(base64String);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new TextDecoder("utf-8").decode(bytes);
}

export const useDownloadOrder = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['downloadOrder'],
    mutationFn: (orderId: string) =>
      api.get<IStandardResponse<IApiResponse>>(`/revenda-pedidos-bff/pedido/baixar/${orderId}`),
    onSuccess: (res) => {
      if (res.data.valor?.arquivo) {
        const blob = new Blob(["\uFEFF" + base64ToUtf8(res.data.valor?.arquivo)], { type: "text/csv;charset=utf-8;" })
        const fileLink = URL.createObjectURL(blob)
        downloadFile({
          download: res.data.valor?.nomeArquivo,
          href: fileLink,
        })
      }
    },
  })

  return {
    downloadOrder: mutate,
    isLoading,
  }
}
