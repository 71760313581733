import { createContext, useMemo } from 'react'
import { Button, Divider, Grid, Paper } from 'presentation/components/atoms'
import { PageHeader } from 'presentation/components/molecules'
import { PaymentDetail } from './components/paymentDetail/paymentDetail'
import { StatusInformation } from './components/statusInformation/statusInformation'
import { OrderItems } from './components/orderItems/orderItems'
import { OrderTrackingSkeleton } from './components/orderTrackingSkeleton/orderTrackingSkeleton'
import { useMediaQuery } from '@mui/material'
import { Content } from 'presentation/components'
import { RemovedItemsCard } from './components/removedItemsCard'
import { IOrderItemsPaged } from './utils/interfaces/iOrderItemsPaged'
import { IOrder } from './utils/interfaces/iOrder'
import { ORDER_STATUS } from '@/main/utils/constants'

type ItemsContent = {
  orderItems: IOrderItemsPaged
  isLoading: boolean
  filter: any
  goToPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  updateFilter: (filterValues: object) => void
}
interface IOrderTrackingContext extends IOrder {
  isRecharge: boolean
  items: ItemsContent
}
export const OrderTrackingContext = createContext<IOrderTrackingContext>({} as IOrderTrackingContext)

interface OrderTrackingProps {
  order: any
  isRecharge: boolean
  isLoading: boolean
  handleReturn: () => void
  items: ItemsContent
}
export const OrderTracking = ({ order, isLoading, isRecharge, handleReturn, items }: OrderTrackingProps) => {
  const shouldBreak = useMediaQuery('@media (max-width:1150px)')

  const contextValue = useMemo(() => ({ ...order, isRecharge, items }), [order, isRecharge, items])
  const isBrokerOrder = true
  const operatorSlip = order?.operatorSlip
  const isInOperatorProcess = order?.orderStatus === ORDER_STATUS.RUNNING
  return (
    <Content>
      <OrderTrackingContext.Provider value={contextValue}>
        <Paper sx={{ padding: '32px', overflow: 'auto' }}>
          <Grid container spacing='16px'>
            <Grid item>
              <PageHeader pageTitle='Acompanhamento de Pedido' padding='0' noDivider />
            </Grid>
            <Grid item xs='auto' sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              {isBrokerOrder && operatorSlip && isInOperatorProcess && (
                <Button variant='contained' onClick={() => window.open(operatorSlip)} disabled={!operatorSlip}>
                  Baixar boleto anexado
                </Button>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading || !order ? (
            <OrderTrackingSkeleton />
          ) : (
            <Grid container spacing='32px' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 8} lg={7}>
                <StatusInformation />
                <OrderItems />
              </Grid>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 4} lg={5}>
                <PaymentDetail />
                {order.removedItems > 0 && <RemovedItemsCard />}
              </Grid>
              {/* {isCancelable &&
                    <Grid item xs={12} sm='auto'>
                      <Button variant='outlined' color='error' onClick={() => setIdToCancel(order.orderId)} fullWidth>
                        Cancelar pedido
                      </Button>
                    </Grid>
                  } */}
              <Grid item xs={12} sm='auto' sx={{ marginLeft: 'auto' }}>
                <Button variant='outlined' onClick={handleReturn} fullWidth>
                  Voltar
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </OrderTrackingContext.Provider>
    </Content>
  )
}
