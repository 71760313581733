import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AutoComplete, Button, ErrorMessage, ErrorWrapper, Input, ModalV2, Spinner } from '@/presentation/components'
import useDownloadReport from '@/main/hooks/useDownloadReport'
import { getCompaniesForReportFilter, postFinancialOrderMovementReport } from '@/services/reportsServices'
import { pushNotification } from '@/store/modules/notification/actions'
import { NOTIFICATION_TYPE } from '@/main/utils/constants'
import errorHandler from '@/main/utils/errorHandler'
import { applyMask, removeObjectEmptyItems } from '@/main/utils/functionUtils'
import PeriodFields from '../../formForFilter/periodFields'
import { CustomContainer, InputWrapper, ModalTitle } from '../styles'
import { Label } from './styles'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'

function OrderMovementReportFilter({ open, onClose }) {
  const dispatch = useDispatch()
  const [companies, setCompanies] = useState([])
  const [isCompanyPresent, setIsCompanyPresent] = useState(false)
  const [newBroker, setNewBroker] = useState(0)
  const isNewBroker = newBroker === 0
  const [financialReportDownloadState, financialReportDownloadRequest] = useDownloadReport(async (title, payload) => {
    try {
      const response = await postFinancialOrderMovementReport(title, payload)

      return response
    } catch (e) {
      return
    }
  })

  const { control, register, handleSubmit, reset } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    const fetch = async () => {
      const { error, response } = await getCompaniesForReportFilter()
      if (response) setCompanies(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
    }

    fetch()
  }, [dispatch])

  const onSubmit = (data) => {
    if (!data.code && !data.companyId && (!data.finalDate || !data.initialDate)) {
      dispatch(
        pushNotification({
          type: NOTIFICATION_TYPE.WARNING,
          content:
            'Para prosseguir é necessário informar Empresa, N° de Pedido ou Período de Movimentação e Tipo de Relatório.',
        }),
      )
      return
    }
    const payload = removeObjectEmptyItems({
      code: data.code ? Number(data.code) : null,
      companyId: data.companyId,
      startDate: data.initialDate,
      endDate: data.finalDate,
      isNewOrder: isNewBroker,
    })
    financialReportDownloadRequest('Relatório de movimentação de pedidos - Financeiro.pdf', payload)
    reset()
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {financialReportDownloadState.loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='genericOrderReportFilter'>
          <CustomContainer fluid>
            <Row>
              <Col>
                <ModalTitle>Relatório de Movimentação de Pedidos</ModalTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <ErrorWrapper error={!isCompanyPresent}>
                  <Label>Empresa</Label>
                  <Controller
                    control={control}
                    name='companyId'
                    render={({ field: { onChange } }) => (
                      <AutoComplete
                        placeholder='Digite aqui o nome, CNPJ ou codigo da empresa'
                        getOptionsLabel={[
                          (option) => option.tradeName,
                          (option) => option.cnpj,
                          (option) => option.code,
                        ]}
                        masks={[null, (option) => applyMask('##.###.###/####-##', option), null]}
                        getOptionValue={(option) => option.id}
                        list={companies}
                        matches={(matches, inputValue) =>
                          matches === 0 && inputValue !== '' ? setIsCompanyPresent(false) : setIsCompanyPresent(true)
                        }
                        onValueChange={(value) => {
                          onChange(value)
                        }}
                      />
                    )}
                  />
                  {!isCompanyPresent && <ErrorMessage>Empresa não encontrada</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <InputWrapper>
                  <Label>N° d{isNewBroker ? 'a Compra' : 'o Pedido'}</Label>
                  <Input
                    placeholder={`Insira o número d${isNewBroker ? 'a Compra' : 'o Pedido'}`}
                    {...register('code')}
                  />
                </InputWrapper>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <InputWrapper>
                  <Label>Período de Movimentação</Label>
                  <PeriodFields control={control} />
                </InputWrapper>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <InputWrapper>
                  <Label>Extrair Relatório</Label>
                </InputWrapper>
                <Input.RadioButton disabled checked onChange={() => null} />
                &nbsp; Financeiro
              </Col>
              <Col xs={12} sm={6} style={{ paddingTop: '24px' }}>
                <ButtonSelector value={newBroker} onChange={({ targetIndex }) => setNewBroker(targetIndex)}>
                  <ButtonSelectorOption index={0}>Broker</ButtonSelectorOption>
                  <ButtonSelectorOption index={1}>Dealer</ButtonSelectorOption>
                </ButtonSelector>
              </Col>
            </Row>

            <Row justify='end'>
              <Col sm='content'>
                <Button>Gerar Relatório</Button>
              </Col>
            </Row>
          </CustomContainer>
        </form>
      )}
    </ModalV2>
  )
}

export default OrderMovementReportFilter
