import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Accordion, Box, Button, Divider, Typography } from '@stationkim/front-ui'
import { EAdministrativeFeeValueType, EBenefitType, EDeskFeeType, ETipoTaxaEntrega } from '@/main/enums'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useDeskFees } from '../../hooks/useDeskFees'
import { useDeskFeesMutation } from '../../hooks/useDeskFeesMutation'
import { AdministrativeFee, TAdministrativeFee } from '../../../components/administrativeFee/administrativeFee'
import { SuccessFee } from '../../../components/successFee/successFee'
import { /* DeliveryFee, */ TDeliveryFee } from '../../../components/deliveryFee/deliveryFee'
import { SlipFee } from '../../../components/slipFee/slipFee'

export type TDeskFeeForm = {
  deliveryFeeType: ETipoTaxaEntrega | ''
  deliveryFees: TDeliveryFee[]
  orderFeeValue?: string
  administrativeFees: TAdministrativeFee[]
  newBalanceProjection: string
  percentual: string
  successFee: string
  slipFee: string
  deliveryFeeByOrderId?: string
}

export const DeskFees = () => {
  const notifications = useNotifications()
  const form = useForm<TDeskFeeForm>({
    mode: 'onChange',
    defaultValues: {
      deliveryFeeType: '',
      deliveryFees: [],
      administrativeFees: [],
      orderFeeValue: '',
    },
  })

  const { deskFees, isLoading: isLoadingDeskFees } = useDeskFees()
  const { deskFeesMutation } = useDeskFeesMutation()
  const isLoading = isLoadingDeskFees || deskFeesMutation.isLoading
  const onSubmit: SubmitHandler<TDeskFeeForm> = (data) => {
    const deliveryFees = data.deliveryFees.map((fee: any) => ({
      ...fee,
      benefitType: EBenefitType.Transport,
      calculationType: EAdministrativeFeeValueType.Percentual,
      valueType: EAdministrativeFeeValueType.Percentual,
      itemType: EDeskFeeType.delivery,
      active: data.deliveryFeeType === ETipoTaxaEntrega.Cartao,
    }))
    deliveryFees.push({
      benefitType: EBenefitType.Transport,
      calculationType: EAdministrativeFeeValueType.Fixed,
      valueType: EAdministrativeFeeValueType.Fixed,
      itemType: EDeskFeeType.delivery,
      active: data.deliveryFeeType === ETipoTaxaEntrega.Pedido,
      feeValue: Number(data.orderFeeValue) || 0,
      startRange: 0,
      endRange: 0,
      id: data.deliveryFeeByOrderId
    })
    const administrativeFees = data.administrativeFees.map(fee => ({
      ...fee,
      itemType: EDeskFeeType.administrative,
      benefitType: fee.benefit
    }))

    const allFees = [...deliveryFees, ...administrativeFees]
    const payload = {
      description: 'description',
      slipFee: Number(data.slipFee),
      successesFee: Number(data.successFee),
      issActive: true,
      newBalanceProjection: data.newBalanceProjection,
      issFee: null,
      fees: allFees.map((fee: any) => {
        const apiFee = ({
          ...fee,
          quantityType: 1,
          "value": Number(fee.feeValue),
        })

        if (fee.initialRange !== undefined) apiFee.startRange = Number(fee.initialRange)
        if (fee.finalRange !== undefined) apiFee.endRange = Number(fee.finalRange)

        return apiFee
      })
    }
    deskFeesMutation.mutate(payload,
      {
        onSuccess: () =>
          notifications.push({
            content: 'Taxas padrão atualizadas!',
            type: EMessageType.Success,
          }),
      },
    )
  }

  useEffect(() => {
    form.reset(deskFees)
  }, [deskFees, form])

  return (
    <Box sx={{ padding: '32px 16px' }}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Typography variant='h1'>Configurações das taxas padrão</Typography>
        <Typography sx={{ margin: '24px 0 32px 0' }} variant='body2'>
          Configure taxas personalizadas. As taxas padrão permitem aplicar valores globais sobre movimentações
          realizadas pelas empresas cadastradas.
        </Typography>
        <Accordion label='Taxa de Boleto' defaultExpanded>
          <SlipFee form={form} isLoading={isLoading} />
        </Accordion>
        <Divider sx={{ marginTop: '16px' }} />
        <Accordion label='Taxas administrativas' defaultExpanded>
          <AdministrativeFee form={form} isLoading={isLoading} />
        </Accordion>
        <Divider />
        <Accordion label='Taxas de sucesso' defaultExpanded>
          <SuccessFee form={form} isLoading={isLoading} isNewSuccessFeeAvailable={false} />
        </Accordion>
        {/* <Divider sx={{ marginTop: '16px' }} />
        <Accordion label='Taxas de entrega' defaultExpanded>
          <Typography sx={{ margin: '24px 0 32px 0' }} variant='body2'>
            Selecione qual tipo de taxa estará ativa e configure seus parâmetros.
          </Typography>
          <DeliveryFee form={form} isLoading={isLoading} />
        </Accordion> */}
        {form.formState.isDirty && (
          <Button
            variant='contained'
            type='submit'
            sx={{ margin: '32px 0 0 auto', display: 'block' }}
            disabled={isLoading}
          >
            Salvar
          </Button>
        )}
      </form>
    </Box>
  )
}
