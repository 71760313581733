import { BENEFIT_TYPE, COMPANY_STATUS, ISS_INCIDENCE, ORDER_STATUS, ORDER_TYPES } from './constants'

export const accountTypes = [
  { label: 'Conta corrente', value: '0' },
  { label: 'Conta poupança', value: '1' },
]

export const benefitsTaxCalculationBy = [
  { label: 'Por pedido', value: 1 },
  { label: 'Por colaborador', value: 2 },
]

export const benefitsTaxCalculationModes = [
  { label: 'Valor', value: 1 },
  { label: 'Percentual', value: 2 },
]

export const benefitsTaxPercentages = [
  { label: '0,0%', value: '0' },
  { label: '1,5%', value: '1.5' },
  { label: '2,0%', value: '2' },
  { label: '2,5%', value: '2.5' },
  { label: '5,0%', value: '5' },
  { label: '8,0%', value: '8' },
]

export const benefitsTypes = [
  { label: 'Vale Transporte Eletrônico', value: BENEFIT_TYPE.TRANSPORT },
  /*  { label: "Vale Alimentação", value: BENEFIT_TYPE.FOOD },
   { label: "Vale Refeição", value: BENEFIT_TYPE.SNACK },
   { label: "Vale Combustível", value: BENEFIT_TYPE.FUEL },
   { label: "Vale Transporte Papel", value: BENEFIT_TYPE.TRANSPORT_PAPER }, */
]

export const branches = [
  { label: 'Atendimento ao Cliente', value: '47' },
  { label: 'Biologia', value: '43' },
  { label: 'Compras', value: '41' },
  { label: 'Contabilidade', value: '42' },
  { label: 'Informática', value: '40' },
  { label: 'Sociologia', value: '44' },
  { label: 'Televendas', value: '45' },
  { label: 'Vendas', value: '39' },
]

export const companyStatus = [
  { label: 'Aguardando aprovação', value: COMPANY_STATUS.WAITING_APPROVAL },
  {
    label: 'Aguardando aprovação do operador',
    value: COMPANY_STATUS.WAITING_OPERATOR,
  },
  { label: 'Aguardando migração', value: COMPANY_STATUS.WAITING_MIGRATION },
  { label: 'Aprovado', value: COMPANY_STATUS.APPROVED },
  { label: 'Criado', value: COMPANY_STATUS.CREATED },
  { label: 'Erro na migração', value: COMPANY_STATUS.MIGRATION_ERROR },
  { label: 'Migrando', value: COMPANY_STATUS.MIGRATING },
  { label: 'Reprovado', value: COMPANY_STATUS.DISAPPROVED },
]

export const issIncidences = [
  { label: '2º via do cartão', value: ISS_INCIDENCE.SECOND_COPY_FEE },
  { label: 'Administrativa', value: ISS_INCIDENCE.MIN_FEE },
  { label: 'Processamento', value: ISS_INCIDENCE.PROCESSING_FEE },
  { label: 'Sucesso', value: ISS_INCIDENCE.SUCCESS_FEE },
]

export const numberOfBeneficiaries = [
  { label: 'Individual/Autônomo', value: 1 },
  { label: '2 a 10 colaboradores', value: 2 },
  { label: '11 a 50 colaboradores', value: 3 },
  { label: '51 a 100 colaboradores', value: 4 },
  { label: '101 a 500 colaboradores', value: 5 },
  { label: 'Mais de 500 colaboradores', value: 6 },
]

export const productStatus = [
  { label: 'ATIVO', value: '1' },
  { label: 'INATIVO', value: '2' },
  { label: 'BLOQUEADO', value: '3' },
]

export const orderStatus = [
  { label: 'Aguardando Pagamento', value: ORDER_STATUS.WAITING_PAYMENT },
  {
    label: 'Aguardando Processamento',
    value: ORDER_STATUS.WAITING_FOR_PROCESSING,
  },
  { label: 'Cancelado', value: ORDER_STATUS.CANCELED },
  { label: 'Concluído', value: ORDER_STATUS.FINISHED },
  { label: 'Disponível para Retirada', value: ORDER_STATUS.AVAILABLE_PICKUP },
  { label: 'Efetivando', value: ORDER_STATUS.EFFECTING },
  { label: 'Em Entrega', value: ORDER_STATUS.DELIVERING },
  { label: 'Em Processamento', value: ORDER_STATUS.PROCESSING },
  { label: 'Processamento Operadora', value: ORDER_STATUS.RUNNING },
  { label: 'Rascunho', value: ORDER_STATUS.DRAFT },
  { label: 'Boleto a prazo pendente', value: ORDER_STATUS.TERM_BILL },
]

export const orderTypes = [
  { label: 'Primeira Via', value: ORDER_TYPES.FIRST_COPY },
  { label: 'Segunda Via', value: ORDER_TYPES.SECOND_COPY },
  { label: 'Recarga', value: ORDER_TYPES.RECHARGE },
  { label: 'Broker', value: ORDER_TYPES.BROKER },
]

export const months = [
  { label: 'Janeiro', value: 'Jan' },
  { label: 'Fevereiro', value: 'Fev' },
  { label: 'Março', value: 'Mar' },
  { label: 'Abril', value: 'Abr' },
  { label: 'Maio', value: 'Mai' },
  { label: 'Junho', value: 'Jun' },
  { label: 'Julho', value: 'Jul' },
  { label: 'Agosto', value: 'Ago' },
  { label: 'Setembro', value: 'Set' },
  { label: 'Outubro', value: 'Out' },
  { label: 'Novembro', value: 'Nov' },
  { label: 'Dezembro', value: 'Dez' },
]

export const states = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AM', value: 'AM' },
  { label: 'AP', value: 'AP' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RO', value: 'RO' },
  { label: 'RS', value: 'RS' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SE', value: 'SE' },
  { label: 'SP', value: 'SP' },
  { label: 'TO', value: 'TO' },
]
