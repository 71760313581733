import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form'
import { Checkbox, formatMoney, Grid, Skeleton, Typography } from '@stationkim/front-ui'
import { StyledISSContainer, StyledLabel, StyledTypography } from './styles'
import { ICompanyIssFee } from '../../../../hooks/interfaces'
import { TCompanyParametersForm } from '../../parameters'
import { issIncidences } from '@/main/utils/options'
// todo use lib multiselect
import { Multiselect } from '@/presentation/components/organisms'
import { ErrorMessage } from '@/presentation/components'

interface IssFeeProps {
  isVisible: boolean
  issInfo: ICompanyIssFee
  control: UseFormReturn<TCompanyParametersForm>['control']
  disabled?: boolean
  isLoading?: boolean
  errors: FieldErrors<TCompanyParametersForm>
}

export const IssFee = ({ issInfo, isVisible, control, disabled, isLoading = true, errors }: IssFeeProps) => {
  return (
    <>
      <StyledTypography variant='h4'>Configurações gerais</StyledTypography>

      {isLoading ? (
        <Skeleton variant='rounded' height='40px' />
      ) : (
        <>
          <StyledLabel sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
            <Controller
              control={control}
              name='issFee'
              render={({ field: { value, onChange } }) => (
                <Checkbox checked={value} disabled={disabled} onChange={(e) => onChange(e.target.checked)} />
              )}
            />
            <Typography>Retenção ISS</Typography>
          </StyledLabel>
          <StyledISSContainer visible={isVisible}>
            <Grid container alignItems='center' spacing='16px'>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>Cidade</Typography>
                <Typography>{issInfo.city}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>Valor</Typography>
                <Typography>{formatMoney(Number(issInfo.value))} %</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography>Taxa</Typography>
                <Controller
                  control={control}
                  name='issIncidences'
                  rules={{
                    validate: {
                      shouldConfig: (incidences, formValue) => {
                        if (formValue.issFee && incidences.length === 0)
                          return 'É obrigatório ter ao menos 1 taxa para aplicação do ISS parametrizada'
                        else true
                      },
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <Multiselect value={value} onChange={onChange} options={issIncidences} disabled={disabled} />
                      {error && (
                        <Typography sx={(theme) => ({ color: theme.palette.error.dark })}>{error.message}</Typography>
                      )}
                    </>
                  )}
                />
                {errors['issIncidences'] ? (
                  <ErrorMessage>{String(errors['issIncidences']?.message)}</ErrorMessage>
                ) : null}
              </Grid>
            </Grid>
          </StyledISSContainer>
        </>
      )}
    </>
  )
}
