import { faArrowRightToBracket, faCircleNotch, faX } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  CircularProgress,
  CopyableField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  formatMoney,
  Grid,
  Icon,
  Typography,
  UserInfo,
} from '@stationkim/front-ui'
import {
  ContactContainer,
  ContactTypography,
  FieldContainer,
  LoadingIcon,
  PendenciesContainer,
  SummaryOrderDataContainer,
} from './styles'
import { OrderType } from '../orderType/orderType'
import { IOrder } from '../../hooks/useOrderList'
import { EStatusCompra, ETipoPendencia } from '@/main/enums'
import { useOrdersFiles } from '../../hooks/useOrdersFiles'
import { Pendency } from './components/pendency/pendency'
import { useDownloadedOrderLayoutStorage } from '../../hooks/useDownloadedOrderLayoutStorage'
import { useResolvePendency } from '../../hooks/useResolvePendency'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getTempOrderPendencyResolutionDateString } from '../../utils/getTempOrderPendencyResolutionDateString'

const noValueText = 'N/A'
const downloadIcon = <Icon icon={faArrowRightToBracket} sx={{ transform: 'rotate(90deg)' }} />

interface SummaryDialogProps {
  open: boolean
  order: IOrder
  onClose: () => void
}

type TGridConfig = number | 'auto' | boolean
const OrderInfo = ({
  title,
  value,
  gridConfig = { xs: 12, sm: 6, md: 3 },
}: {
  title: string
  value: string | React.ReactNode
  gridConfig?: { xs?: TGridConfig; sm?: TGridConfig; md?: TGridConfig }
}) => {
  return (
    <Grid item {...gridConfig}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 700 }}>{title}</Typography>
      {typeof value === 'string' ? (
        <Typography
          sx={(theme) => ({
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.palette.grey[700],
          })}
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </Grid>
  )
}

export const SummaryDialog = ({ open, onClose, order }: SummaryDialogProps) => {
  const orderId = order?.id
  const queryClient = useQueryClient()
  const [tempDateString, setTempDateString] = useState({
    [ETipoPendencia.Colaborador]: '',
    [ETipoPendencia.Pedido]: '',
  })
  const { resolvePendency } = useResolvePendency()
  const { getOrderLayout } = useOrdersFiles({
    orderNumber: order?.orderNumber,
    orderId,
  })
  const isLayoutAvailable = order.orderStatus > EStatusCompra.AguardandoPagamento

  const { updateDownloaded, downloadedLayouts } = useDownloadedOrderLayoutStorage({ orderId })
  const handleLayoutDownloadClick = (getLayout: () => void, type: 'order' | 'employee') => {
    updateDownloaded(type)
    getLayout()
  }

  const setTempDate = (type: ETipoPendencia) => {
    setTempDateString((state) => ({
      ...state,
      [type]: getTempOrderPendencyResolutionDateString(),
    }))
  }

  const renderPendencies = () => {
    const pendenciesToRender = [
      {
        fileName: 'Layout Colab',
        disabled: !downloadedLayouts.employee,
        onResolve: () =>
          resolvePendency.mutate(
            {
              orderId: order.id,
              pendencyType: ETipoPendencia.Colaborador,
              resolved: true,
              purchaseId: order.purchaseId,
            },
            {
              onSuccess: () => {
                setTempDate(ETipoPendencia.Colaborador)
                queryClient.invalidateQueries({ queryKey: ['purchases'] })
              },
            },
          ),
        type: ETipoPendencia.Colaborador,
        id: 'order_pendency_employeeLayout',
      },
      {
        fileName: 'Layout Pedido',
        disabled: !downloadedLayouts.order,
        onResolve: () =>
          resolvePendency.mutate(
            {
              orderId: order.id,
              pendencyType: ETipoPendencia.Pedido,
              resolved: true,
              purchaseId: order.purchaseId,
            },
            {
              onSuccess: () => {
                setTempDate(ETipoPendencia.Pedido)
                queryClient.invalidateQueries({ queryKey: ['purchases'] })
              },
            },
          ),
        type: ETipoPendencia.Pedido,
        id: 'order_pendency_orderLayout',
      },
    ]
    return (
      <PendenciesContainer>
        {pendenciesToRender.map((pendency, index) => {
          const orderPendency = order.pendencies?.find((orderPendency) => orderPendency.type === pendency.type)
          const pendencyTempDateString = tempDateString[pendency.type]
          return resolvePendency.variables?.pendencyType === pendency.type && resolvePendency.isLoading ? (
            <Box sx={{ margin: '8px 32px' }} key={order.id + '_' + index}>
              <CircularProgress size='16px' />
            </Box>
          ) : (
            <Pendency
              key={order.id + '_' + index}
              fileName={pendency.fileName}
              onResolve={pendency.onResolve}
              disabled={pendency.disabled}
              showTooltip={isLayoutAvailable && Boolean(orderPendency) && !orderPendency?.resolutionDate}
              resolveDate={pendencyTempDateString !== '' ? pendencyTempDateString : orderPendency?.resolutionDate}
              id={pendency.id}
            />
          )
        })}
      </PendenciesContainer>
    )
  }

  const hasEmployeePendency = order.pendencies.some((pendency) => pendency.type === ETipoPendencia.Colaborador)
  return (
    <Dialog open={open} maxWidth='md' onClose={onClose}>
      <DialogTitle sx={{ marginBottom: '32px' }}>
        {order.orderNumber}
        <Button onClick={onClose} sx={{ minWidth: 'unset' }} disableRipple>
          <Icon icon={faX} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <SummaryOrderDataContainer sx={{ marginBottom: '32px' }}>
          <Grid container columnSpacing='32px' rowSpacing='32px'>
            <OrderInfo title='Nº pedido' value={order.orderNumber} />
            <OrderInfo title='Data' value={order.orderDate} />
            <OrderInfo title='Operadora' value={order.operator} />
            <OrderInfo title='Reserva' value={<UserInfo name='Não Atribuído' role='' />} />
            <OrderInfo title='Tipo' value={<OrderType type={order.orderType} />} />
            <OrderInfo title='Nº colabs.' value={order.totalItensInOrder} />
            <OrderInfo title='Data para crédito' value={order.creditDate ?? noValueText} />
            <OrderInfo title='Valor total' value={'R$ ' + formatMoney(order.totalValue)} />
          </Grid>
        </SummaryOrderDataContainer>
        <Grid container columnSpacing='32px' rowSpacing='32px'>
          <OrderInfo
            title='Arquivos'
            value={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  '& button': { justifyContent: 'start', padding: '0' },
                  '& button:hover': { background: 'none' },
                }}
              >
                <Button
                  disabled={!hasEmployeePendency || !isLayoutAvailable}
                  disableTouchRipple
                  variant='softText'
                  endIcon={downloadIcon}
                  size='small'
                  sx={{ width: '160px' }}
                  id='order_summaryDialog_button_employeeLayout'
                  onClick={
                    getOrderLayout.isLoading
                      ? null
                      : () =>
                        handleLayoutDownloadClick(
                          () =>
                            getOrderLayout.mutate({ orderId: order.id, pendencyType: ETipoPendencia.Colaborador }),
                          'employee',
                        )
                  }
                >
                  Layout Colab
                </Button>
                <Button
                  variant='softText'
                  endIcon={getOrderLayout.isLoading ? <LoadingIcon icon={faCircleNotch} /> : downloadIcon}
                  size='small'
                  sx={{ width: '160px' }}
                  fullWidth={false}
                  onClick={
                    getOrderLayout.isLoading
                      ? null
                      : () => handleLayoutDownloadClick(() => getOrderLayout.mutate({ orderId: order.id }), 'order')
                  }
                  disabled={!isLayoutAvailable}
                  disableTouchRipple
                  id='order_summaryDialog_button_orderLayout'
                >
                  Layout Pedido
                </Button>
              </Box>
            }
            gridConfig={{ xs: 12, sm: 6, md: 'auto' }}
          />
          <OrderInfo title='Pendência' value={renderPendencies()} gridConfig={{ xs: 12, sm: 6, md: 'auto' }} />
          <OrderInfo
            title='Dados da operadora'
            value={
              <ContactContainer>
                <ContactTypography>
                  Site:{' '}
                  {order.operatorSite ? (
                    <a href={order.operatorSite} title={order.operatorSite} target='_blank' rel='noreferrer'>
                      {order.operatorSite}
                    </a>
                  ) : (
                    noValueText
                  )}
                </ContactTypography>
                <ContactTypography sx={{ fontSize: '.875rem', margin: '8px 0', fontWeight: 500 }}>
                  Contato:{' '}
                  {order.operatorEmail ? (
                    <a href={'mailto:' + order.operatorEmail} title={order.operatorEmail}>
                      {order.operatorEmail}
                    </a>
                  ) : (
                    noValueText
                  )}
                </ContactTypography>
              </ContactContainer>
            }
            gridConfig={{ xs: 12, sm: 6, md: 3 }}
          />
          <OrderInfo
            title='Dados de acesso'
            gridConfig={{ xs: 12, sm: 6, md: true }}
            value={
              <>
                <FieldContainer title={order.operatorUser} sx={{ marginBottom: '22px' }}>
                  Usuário: <CopyableField disabled value={order.operatorUser} />
                </FieldContainer>
                <FieldContainer>
                  Senha: <CopyableField disabled value={order.operatorPassword} isPassword />
                </FieldContainer>
              </>
            }
          />
        </Grid>
        <Divider sx={{ margin: '24px 0 ' }} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'start' }}>
        <Button variant='outlined' color='error' size='medium' disabled>
          Cancelar Pedido
        </Button>
        <Button variant='outlined' sx={{ filter: 'grayscale(100)' }} size='medium' disabled>
          Retirar reserva
        </Button>
      </DialogActions>
    </Dialog>
  )
}
