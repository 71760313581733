import apiCallHandler from './apiCallHandler'
import api from '../infra/api'

export const getCompaniesForReportFilter = () => apiCallHandler(() => api.get('resale-bff/reports/selects/companies'))

export const getCardsForReportFilter = (companyId, cardNumber = '') =>
  apiCallHandler(() =>
    api.get(`resale-bff/reports/selects/cards?CompanyId=${companyId}${cardNumber && `&Number=%25${cardNumber}`}`),
  )

export const getSettlePaymentReport = (settlePaymentId) =>
  apiCallHandler(() =>
    api.get(`/reports-service/settlepayments/${settlePaymentId}/pdf`, {
      responseType: 'blob',
    }),
  )

export const getCardOrdersReport = (orderNumber, isNewBroker) =>
  apiCallHandler(() =>
    api.get(`/reports-service/orders/${orderNumber}/cards/pdf?isNewOrder=${isNewBroker}`, {
      responseType: 'blob',
    }),
  )

export const getRechargeOrdersReport = (orderNumber, isNewBroker) =>
  apiCallHandler(() =>
    api.get(`/reports-service/orders/${orderNumber}/recharges/pdf?isNewOrder=${isNewBroker}`, {
      responseType: 'blob',
    }),
  )

export const getBalanceProjectionOrderReport = (query) =>
  apiCallHandler(() =>
    api.get(`/reports-service/orders/balance-projection/pdf${query}`, {
      responseType: 'blob',
    }),
  )

export const postFinancialOrderMovementReport = (payload) =>
  apiCallHandler(() =>
    api.post('/reports-service/orders/financial/pdf', payload, {
      responseType: 'blob',
    }),
  )

export const getRepassReport = (operatorId, query) =>
  apiCallHandler(() =>
    api.get(`/reports-service/transfer/operator/${operatorId}/pdf${query}`, {
      responseType: 'blob',
    }),
  )

export const getCardsLoadedAndNoLoaded = (orderCode, companyId) =>
  apiCallHandler(() =>
    api.get(`/reports-service/orders/${orderCode}/cards/recharges/pdf${companyId && `?companyId=${companyId}`}`, {
      responseType: 'blob',
    }),
  )

export const getAnaliticCardBalanceReport = (payload) =>
  apiCallHandler(() =>
    api.post('/reports-service/balance/analytical/pdf', payload, {
      responseType: 'blob',
    }),
  )

export const getSyntheticCardBalanceReport = (payload) =>
  apiCallHandler(() =>
    api.post('/reports-service/balance/synthetic/pdf', payload, {
      responseType: 'blob',
    }),
  )

export const getCardsWithoutRechargeReport = (query = '') =>
  apiCallHandler(() =>
    api.get(`/reports-service/cards/CardsWithoutRecharge/pdf${query}`, {
      responseType: 'blob',
    }),
  )

export const getBlockedCardsWithoutSecondCopyReport = (query = '') =>
  apiCallHandler(() =>
    api.get(`/reports-service/cards/Blocked/pdf${query}`, {
      responseType: 'blob',
    }),
  )
