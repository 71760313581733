import { Controller, UseFormReturn } from 'react-hook-form'
import { Box, CheckboxField, MaskedTextField, Skeleton } from '@stationkim/front-ui'

interface SuccessFeeProps {
  form: UseFormReturn<any>
  isNewSuccessFeeAvailable?: boolean
  isLoading?: boolean
}

export const SuccessFee = ({ form, isLoading, isNewSuccessFeeAvailable = true }: SuccessFeeProps) => {
  const isNewBalanceProjection = form.watch('newBalanceProjection')

  return isLoading ? (
    <Skeleton variant='rounded' height='40px' />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Controller
        control={form.control}
        name='successFee'
        rules={{
          validate: {
            isBiggerThanZero: (value) =>
              Number(value) > 0 || !isNewBalanceProjection ? true : 'O valor deve ser superior a 0,00%',
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <MaskedTextField
              mask={'percentual'}
              label='Valor'
              placeholder='00,00 %'
              fullWidth={false}
              value={value}
              onChange={onChange}
              required={isNewBalanceProjection}
              unmask
              helperText={error?.message}
              error={Boolean(error)}
            />
          )
        }}
      />
      {isNewSuccessFeeAvailable &&
        <Controller
          control={form.control}
          name='newBalanceProjection'
          render={({ field: { onChange, value } }) => {
            return (
              <CheckboxField
                value={value}
                label='Nova taxa de projeção de saldo'
                onChange={(e) => onChange(e.target.checked)}
              />
            )
          }}
        />
      }
    </Box>
  )
}
