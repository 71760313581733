import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { Button, Input, ModalV2, Spinner } from '@/presentation/components'
import useDownloadReport from '@/main/hooks/useDownloadReport'
import { getRechargeOrdersReport } from '@/services/reportsServices'
import { CustomContainer, InputWrapper, ModalTitle } from '../styles'
import { CheckBoxWrapper, Label, WrapperOption } from './styles'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'

function RechargeOrderReportFilter({ open, onClose }) {
  const [newBroker, setNewBroker] = useState(0)

  const isNewBroker = newBroker === 0

  const [downloadState, download] = useDownloadReport(async (query) => {
    const response = await getRechargeOrdersReport(query, isNewBroker)

    if (response?.response?.status !== 200) {
      return {
        error: true,
        ...response,
      }
    }

    return response
  })
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  })

  const onSubmit = (data) => {
    if (!data.cardOrder) return
    download(`Relatório de pedido de recarga ${data.cardOrder}.pdf`, data.cardOrder)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {downloadState.loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='RechargeOrderReportFilterForm'>
          <CustomContainer fluid>
            <Row>
              <Col>
                <ModalTitle>Relatório de Pedido de Recarga</ModalTitle>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <InputWrapper>
                  <Label>N° d{isNewBroker ? 'a Compra' : 'o Pedido'}</Label>
                  <Input
                    type='number'
                    placeholder={`Insira o número d${isNewBroker ? 'a Compra' : 'o Pedido'}`}
                    {...register('cardOrder')}
                  />
                </InputWrapper>
              </Col>
            </Row>
            <Row align='center'>
              <Col sm={2}>
                <CheckBoxWrapper>
                  <Input.CheckBox checked controlled />
                  <span>PDF</span>
                </CheckBoxWrapper>
              </Col>

              <Col sm={10}>
                <WrapperOption>
                  <ButtonSelector value={newBroker} onChange={({ targetIndex }) => setNewBroker(targetIndex)}>
                    <ButtonSelectorOption index={0}>Broker</ButtonSelectorOption>
                    <ButtonSelectorOption index={1}>Dealer</ButtonSelectorOption>
                  </ButtonSelector>
                </WrapperOption>
              </Col>
            </Row>
            <Row justify='end'>
              <Col sm='content'>
                <Button>Gerar Relatório</Button>
              </Col>
            </Row>
          </CustomContainer>
        </form>
      )}
    </ModalV2>
  )
}

export default RechargeOrderReportFilter
