import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { TUser } from '../components/purchaseListFilters/components/userAttributionFilter/userAttributionFilter'
import api from '@/infra/api'

//Atualizar essa interface conforme a resposta do BE
interface IApiResponse {
  nome: string
  sobrenome: string
  imagem: string
  total: number
  id: string
}

interface IStandardResponse<T> {
  mensagens: string[]
  sucesso: boolean
  responsaveis: T
}

export const useResaleUsers = ({ resaleId }: { resaleId: string }) => {
  const { data, isLoading } = useQuery<IStandardResponse<IApiResponse[]>>({
    queryKey: ['userProfileFilter', resaleId],
    queryFn: async () => {
      const { data } = await api.get(`/revenda-pedidos-bff/pedido/responsaveis/${resaleId}`)
      return data
    },
    meta: { silent: true },
  })

  const organizedData = useMemo((): TUser[] => {
    if (!data) return []
    const sortedData = data?.responsaveis?.sort((a, b) => {
      if (a.total > b.total) return -1
      if (a.total < b.total) return 1
      return 0
    })
    //Mapeando os itens da api pra forma como o sistema vai utilizar
    return sortedData.map((item) => ({
      id: item.id,
      image: item.imagem,
      name: `${item.nome?.toUpperCase() ?? ''} ${item.sobrenome?.toUpperCase() ?? ''}`,
      notificationCount: item.total,
    }))
  }, [data])

  return {
    users: organizedData,
    isLoading,
  }
}
