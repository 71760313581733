import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { TDeskFeeForm } from '../components/deskFees/deskFees'
import { AxiosResponse } from 'axios';
import { EAdministrativeFeeCalculationType, EAdministrativeFeeValueType, EBenefitType, ECalculationType, EDeskFeeType, ETipoTaxaEntrega } from '@/main/enums';

type Fee = {
  id: string;
  benefitType: EBenefitType;
  itemType: EDeskFeeType;
  calculationType: EAdministrativeFeeCalculationType;
  quantityType: number;
  valueType: EAdministrativeFeeValueType;
  value: number;
  startRange: number;
  endRange: number;
  active: boolean
};

interface ApiReturn {
  fees: Fee[];
  description: string;
  orderProcessingDays: number;
  slipFee: number;
  aliquotIss: number;
  successFee: number;
}

export const useDeskFees = () => {
  const { data, isLoading } = useQuery<AxiosResponse<ApiReturn>>({
    queryKey: ['deskFees'],
    queryFn: () => api.get('/resales-service/DeskFees'),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  const deskFees = useMemo((): TDeskFeeForm => {
    if (!data) return {} as TDeskFeeForm
    const deliveryFeeType = data.data.fees.some(fee => {
      const calcType = fee.calculationType as unknown as ECalculationType
      return fee.itemType === EDeskFeeType.delivery &&
        calcType === ECalculationType.ByOrder &&
        fee.active
    }) ? ETipoTaxaEntrega.Pedido : ETipoTaxaEntrega.Cartao
    const deliveryFeeByOrder = data.data.fees
      .find(fee => fee.itemType === EDeskFeeType.delivery && fee.valueType === EAdministrativeFeeValueType.Fixed)
    const deliveryFees = data.data.fees
      .filter(fee => fee.itemType === EDeskFeeType.delivery && fee.valueType === EAdministrativeFeeValueType.Percentual)
      .map((fee) => ({
        feeValue: String(fee.value),
        finalRange: String(fee.endRange),
        initialRange: String(fee.startRange),
        id: fee.id
      }))
    return {
      administrativeFees: data.data.fees
        .filter(fee => fee.itemType === EDeskFeeType.administrative)
        .map((fee) => ({
          calculationType: fee.calculationType,
          valueType: fee.valueType,
          benefit: fee.benefitType,
          feeValue: String(fee.value),
          initialRange: String(fee.startRange),
          finalRange: String(fee.endRange),
          id: fee.id
        })),
      deliveryFees: deliveryFees,
      deliveryFeeType,
      successFee: String(data.data.successFee),
      slipFee: String(data.data.slipFee),
      orderFeeValue: String(deliveryFeeByOrder?.value),
      deliveryFeeByOrderId: deliveryFeeByOrder?.id,
      //Não existe BE para os dados abaixo quando falando de DeskFee
      newBalanceProjection: null,
      percentual: null,
    }
  }, [data])

  return {
    deskFees,
    isLoading,
  }
}
