import api from '@/infra/api'
import { useMutation } from '@tanstack/react-query'

//atualizar tipo e requisição
type TApiPayload = any

export const useDeskFeesMutation = () => {
  const deskFeesMutation = useMutation({
    mutationKey: ['deskFeesMutation'],
    mutationFn: (payload: TApiPayload) => api.put('/resales-service/DeskFees', payload),
  })

  return {
    deskFeesMutation,
  }
}
